




































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import VuetifyGoogleAutocomplete from "./VuetifyGoogleAutocomplete.vue";
import {
  dispatchCheckLoggedIn,
  dispatchSelectPlan,
  dispatchGetServiceAddress,
  dispatchGetBillingAddress,
} from "@/store/main/actions";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import IDVerifyForeign from "./IDVerifyForeign.vue";
import IDVerifyLocal from "./IDVerifyLocal.vue";
import { readServiceAddress, readBillingAddress } from "@/store/main/getters";
import _ from "lodash";
import Progress from "./Progress.vue";

@Component({
  components: {
    VuetifyGoogleAutocomplete,
    Header,
    Footer,
    IDVerifyForeign,
    IDVerifyLocal,
    Progress,
  },
})
export default class IDVerify extends Vue {
  public valid = true;
  public tabs = "";
  public tabTitles = [
    {
      id: 0,
      title: "Filipino Citizen",
      class: "GreyBorder2",
      activeClass: "GreenBorder",
    },
    {
      id: 1,
      title: "Non-Filipino Citizen",
      class: "GreyBorder",
      activeClass: "GreenBorder2",
    },
  ];
  public async mounted() {
    if (
      !location.host.startsWith("op") &&
      !location.host.startsWith("dev") &&
      !location.host.startsWith("localhost")
    ) {
      this.$gtag.event("conversion", {
        send_to: "AW-368517415/RieCCLqlp8QCEKfC3K8B",
      });
    }
  }
}
