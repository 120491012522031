




































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { appName } from "@/env";
import { readLoginError } from "@/store/main/getters";
import VuetifyGoogleAutocomplete from "./VuetifyGoogleAutocomplete.vue";
import { dispatchUploadDocs } from "@/store/main/actions";
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import { readServiceAddress, readBillingAddress } from "@/store/main/getters";
import _ from "lodash";
import VueUploadMultipleImage from "./VueUploadMultipleImage.vue";
import UploadButton from "../components/UploadButton.vue";

import Loading from "./Loading.vue";

@Component({
  components: {
    VuetifyGoogleAutocomplete,
    Header,
    Footer,
    VueUploadMultipleImage,
    Loading,
  },
})
export default class VerifyForeign extends Vue {
  public images = [];
  public showPrimary = false;
  public multiple = true;

  public IDFiles = Array();
  public BillFiles = Array();
  public showLoading = false;

  async uploadImageSuccessID(formData, index, fileList) {
    const names = this.IDFiles.map((item) => _.get(item, "name", ""));
    this.IDFiles = fileList; //[...fileList.filter((item) => !names.includes(item.name))];
  }
  async uploadImageSuccessBill(formData, index, fileList) {
    const names = this.BillFiles.map((item) => _.get(item, "name", ""));
    this.BillFiles = fileList; // [...fileList.filter((item) => !names.includes(item.name))];
  }
  beforeRemove(index, done, fileList) {
    var r = confirm("remove image");
    if (r == true) {
      done();
    } else {
    }
  }
  editImage(formData, index, fileList) {}

  dataChange(change) {}

  async submit() {
    this.showLoading = true;
    await dispatchUploadDocs(this.$store, {
      id_files: this.IDFiles,
      bill_files: this.BillFiles,
      is_local: false,
    });
  }

  validate() {
    if (
      this.IDFiles &&
      this.IDFiles.length > 0 &&
      this.BillFiles &&
      this.BillFiles.length > 0
    ) {
      return true;
    }
    return false;
  }
}
